/*
Copyright 2015 - 2021 The Matrix.org Foundation C.I.C.

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

import React, { createRef } from "react";
import {
    EventType,
    MsgType,
    MatrixEventEvent,
    M_BEACON_INFO,
    M_LOCATION,
    M_POLL_END,
    M_POLL_START,
} from "matrix-js-sdk/src/matrix";
import SettingsStore from "matrix-react-sdk/src/settings/SettingsStore";
import { Mjolnir } from "matrix-react-sdk/src/mjolnir/Mjolnir";
import RedactedBody from "matrix-react-sdk/src/components/views/messages/RedactedBody";
import UnknownBody from "matrix-react-sdk/src/components/views/messages/UnknownBody";
import { IMediaBody } from "matrix-react-sdk/src/components/views/messages/IMediaBody";
import { MediaEventHelper } from "matrix-react-sdk/src/utils/MediaEventHelper";
import MatrixClientContext from "matrix-react-sdk/src/contexts/MatrixClientContext";
import TextualBody from "matrix-react-sdk/src/components/views/messages/TextualBody";
import MImageBody from "matrix-react-sdk/src/components/views/messages/MImageBody";
import MFileBody from "matrix-react-sdk/src/components/views/messages/MFileBody";
import MVoiceOrAudioBody from "matrix-react-sdk/src/components/views/messages/MVoiceOrAudioBody";
import MVideoBody from "matrix-react-sdk/src/components/views/messages/MVideoBody";
import MStickerBody from "matrix-react-sdk/src/components/views/messages/MStickerBody";
import MPollBody from "matrix-react-sdk/src/components/views/messages/MPollBody";
import { MPollEndBody } from "matrix-react-sdk/src/components/views/messages/MPollEndBody";
import MLocationBody from "matrix-react-sdk/src/components/views/messages/MLocationBody";
import MjolnirBody from "matrix-react-sdk/src/components/views/messages/MjolnirBody";
import MBeaconBody from "matrix-react-sdk/src/components/views/messages/MBeaconBody";
import { DecryptionFailureBody } from "matrix-react-sdk/src/components/views/messages/DecryptionFailureBody";
import { GetRelationsForEvent, IEventTileOps } from "matrix-react-sdk/src/components/views/rooms/EventTile";
import {
    VoiceBroadcastBody,
    VoiceBroadcastInfoEventType,
    VoiceBroadcastInfoState,
} from "matrix-react-sdk/src/voice-broadcast";
// CTalk imported
import { MatrixClientPeg } from "matrix-react-sdk/src/MatrixClientPeg";
import { User } from "matrix-js-sdk/src/matrix";
// import { _t } from "matrix-react-sdk/src/languageHandler";
import { Layout } from "matrix-react-sdk/src/settings/enums/Layout";
import { ImageSize, suggestedSize as suggestedImageSize } from "matrix-react-sdk/src/settings/enums/ImageSize";
import { logger } from "matrix-js-sdk/src/logger";
import MemberAvatar from "matrix-react-sdk/src/components/views/avatars/MemberAvatar";
import { RoomMember } from "matrix-js-sdk/src/models/room-member";
import { getForwardedEventInfo, shortenUserId } from "@ctalk/utils/helper";
import CFormBody from "@ctalk/views/messages/CFormBody";
import { ECustomMsgType } from "@ctalk/enums/custom-event";
import { getAutoDeleteState } from "@ctalk/components/views/AutoDelete";

import { _t } from "../../../languageHandler";
import { IBodyProps } from "./IBodyProps";

// onMessageAllowed is handled internally
interface IProps extends Omit<IBodyProps, "onMessageAllowed" | "mediaEventHelper"> {
    /* overrides for the msgtype-specific components, used by ReplyTile to override file rendering */
    overrideBodyTypes?: Record<string, typeof React.Component>;
    overrideEventTypes?: Record<string, typeof React.Component>;

    // helper function to access relations for this event
    getRelationsForEvent?: GetRelationsForEvent;

    isSeeingThroughMessageHiddenForModeration?: boolean;

    isRightPanelPinnedMessage?: boolean;
}

export interface IOperableEventTile {
    getEventTileOps(): IEventTileOps | null;
}

const baseBodyTypes = new Map<string, typeof React.Component>([
    [MsgType.Text, TextualBody],
    [MsgType.Notice, TextualBody],
    [MsgType.Emote, TextualBody],
    [MsgType.Image, MImageBody],
    [MsgType.File, MFileBody],
    [MsgType.Audio, MVoiceOrAudioBody],
    [MsgType.Video, MVideoBody],
    [ECustomMsgType.CForm, CFormBody],
]);
const baseEvTypes = new Map<string, React.ComponentType<IBodyProps>>([
    [EventType.Sticker, MStickerBody],
    [M_POLL_START.name, MPollBody],
    [M_POLL_START.altName, MPollBody],
    [M_POLL_END.name, MPollEndBody],
    [M_POLL_END.altName, MPollEndBody],
    [M_BEACON_INFO.name, MBeaconBody],
    [M_BEACON_INFO.altName, MBeaconBody],
]);

export default class MessageEvent extends React.Component<IProps, any> implements IMediaBody, IOperableEventTile {
    private body: React.RefObject<React.Component | IOperableEventTile> = createRef();
    private mediaHelper?: MediaEventHelper;
    private bodyTypes = new Map<string, typeof React.Component>(baseBodyTypes.entries());
    private evTypes = new Map<string, React.ComponentType<IBodyProps>>(baseEvTypes.entries());

    public static contextType = MatrixClientContext;
    public context!: React.ContextType<typeof MatrixClientContext>;

    public constructor(props: IProps, context: React.ContextType<typeof MatrixClientContext>) {
        super(props, context);

        if (MediaEventHelper.isEligible(this.props.mxEvent)) {
            this.mediaHelper = new MediaEventHelper(this.props.mxEvent);
        }

        this.updateComponentMaps();
    }

    public componentDidMount(): void {
        this.props.mxEvent.addListener(MatrixEventEvent.Decrypted, this.onDecrypted);
    }

    public componentWillUnmount(): void {
        this.props.mxEvent.removeListener(MatrixEventEvent.Decrypted, this.onDecrypted);
        this.mediaHelper?.destroy();
    }

    public componentDidUpdate(prevProps: Readonly<IProps>): void {
        if (this.props.mxEvent !== prevProps.mxEvent && MediaEventHelper.isEligible(this.props.mxEvent)) {
            this.mediaHelper?.destroy();
            this.mediaHelper = new MediaEventHelper(this.props.mxEvent);
        }

        this.updateComponentMaps();
    }

    private updateComponentMaps(): void {
        this.bodyTypes = new Map<string, typeof React.Component>(baseBodyTypes.entries());
        for (const [bodyType, bodyComponent] of Object.entries(this.props.overrideBodyTypes ?? {})) {
            this.bodyTypes.set(bodyType, bodyComponent);
        }

        this.evTypes = new Map<string, React.ComponentType<IBodyProps>>(baseEvTypes.entries());
        for (const [evType, evComponent] of Object.entries(this.props.overrideEventTypes ?? {})) {
            this.evTypes.set(evType, evComponent);
        }
    }

    public getEventTileOps = (): IEventTileOps | null => {
        return (this.body.current as IOperableEventTile)?.getEventTileOps?.() || null;
    };

    public getMediaHelper(): MediaEventHelper | undefined {
        return this.mediaHelper;
    }

    private onDecrypted = (): void => {
        // Recheck MediaEventHelper eligibility as it can change when the event gets decrypted
        if (MediaEventHelper.isEligible(this.props.mxEvent)) {
            this.mediaHelper?.destroy();
            this.mediaHelper = new MediaEventHelper(this.props.mxEvent);
        }
    };

    private onTileUpdate = (): void => {
        this.forceUpdate();
    };

    private getForwardedUser = (userId: string): User | null | undefined => {
        const cli = MatrixClientPeg.safeGet();
        const userInfo = cli.store.getUser(userId);
        // case 1: userInfo is null
        // case 2: userInfo.displayName = userId (shorten) -> deleted user
        if (!userInfo || (userInfo && shortenUserId(userInfo.userId) === userInfo.displayName)) {
            const newUser = User.createUser(userId, cli);
            cli.getProfileInfo(userId).then((userFetch) => {
                // case 1: userInfo { } -> user deleted
                if (!Object.keys(userFetch).length) {
                    newUser.displayName = _t("CTALK_DELETED_USER");
                } else {
                    // case 2: userInfo { avatarUrl: string; displayname: string }
                    newUser.displayName = userFetch.displayname;
                }
                newUser.rawDisplayName = newUser.displayName;
                cli.store.storeUser(newUser);
                this.forceUpdate();
                return newUser;
            }).catch((e) => {
                logger.error(e);
            });
        }
        return userInfo;
    };

    private findMember(userId: string): RoomMember | null | undefined {
        const cli = MatrixClientPeg.safeGet();
        const room = cli.getRoom(this.props.mxEvent.getRoomId());
        return room?.getMember(userId);
    }

    private forwardedEvent = (forwardedInfo: any): React.ReactNode => {
        const userInfo = this.getForwardedUser(forwardedInfo.user_id);
        const member = userInfo && this.findMember(forwardedInfo.user_id);
        return (
            <div className="ctalk_MessageEvent_forward">
                <span className="ctalk_MessageEvent_forwardTitle">{ _t("CTALK_FORWARDED_FROM") }</span>
                <span className="ctalk_MessageEvent_forwardUser">
                    {
                        member && <MemberAvatar
                            key={forwardedInfo.user_id}
                            member={member}
                            size="14px"
                        />
                    }
                    <strong className="ctalk_MessageEvent_forwardName">{userInfo?.displayName}</strong>
                </span>
            </div>
        );
    };

    public render(): React.ReactNode {
        const content = this.props.mxEvent.getContent();
        const type = this.props.mxEvent.getType();
        const msgtype = content.msgtype;
        const layout = SettingsStore.getValue("layout");
        let BodyType: React.ComponentType<IBodyProps> = RedactedBody;

        // CTalk added
        // Check deleted message
        let isDeletedEvent = false;
        const room = MatrixClientPeg.safeGet().getRoom(this.props.mxEvent.getRoomId());
        const autoDeleteState = getAutoDeleteState(room!);
        if (
            autoDeleteState.expiredTs &&
            this.props.mxEvent.getTs() &&
            this.props.mxEvent.getTs() < autoDeleteState.expiredTs
        ) {
            isDeletedEvent = true;
        }

        if (!this.props.mxEvent.isRedacted() && !isDeletedEvent) {
            // only resolve BodyType if event is not redacted
            if (this.props.mxEvent.isDecryptionFailure()) {
                BodyType = DecryptionFailureBody;
            } else if (type && this.evTypes.has(type)) {
                BodyType = this.evTypes.get(type)!;
            } else if (msgtype && this.bodyTypes.has(msgtype)) {
                BodyType = this.bodyTypes.get(msgtype)!;
            } else if (content.url) {
                // Fallback to MFileBody if there's a content URL
                BodyType = this.bodyTypes.get(MsgType.File)!;
            } else {
                // Fallback to UnknownBody otherwise if not redacted
                BodyType = UnknownBody;
            }

            // TODO: move to eventTypes when location sharing spec stabilises
            if (M_LOCATION.matches(type) || (type === EventType.RoomMessage && msgtype === MsgType.Location)) {
                BodyType = MLocationBody;
            }

            if (type === VoiceBroadcastInfoEventType && content?.state === VoiceBroadcastInfoState.Started) {
                BodyType = VoiceBroadcastBody;
            }
        }

        if (SettingsStore.getValue("feature_mjolnir")) {
            const key = `mx_mjolnir_render_${this.props.mxEvent.getRoomId()}__${this.props.mxEvent.getId()}`;
            const allowRender = localStorage.getItem(key) === "true";

            if (!allowRender) {
                const userDomain = this.props.mxEvent.getSender()?.split(":").slice(1).join(":");
                const userBanned = Mjolnir.sharedInstance().isUserBanned(this.props.mxEvent.getSender()!);
                const serverBanned = userDomain && Mjolnir.sharedInstance().isServerBanned(userDomain);

                if (userBanned || serverBanned) {
                    BodyType = MjolnirBody;
                }
            }
        }
        const bodyType = BodyType ? (
            <BodyType
                ref={this.body}
                mxEvent={this.props.mxEvent}
                highlights={this.props.highlights}
                highlightLink={this.props.highlightLink}
                showUrlPreview={this.props.showUrlPreview}
                forExport={this.props.forExport}
                maxImageHeight={this.props.maxImageHeight}
                replacingEventId={this.props.replacingEventId}
                editState={this.props.editState}
                onHeightChanged={this.props.onHeightChanged}
                onMessageAllowed={this.onTileUpdate}
                permalinkCreator={this.props.permalinkCreator}
                mediaEventHelper={this.mediaHelper}
                getRelationsForEvent={this.props.getRelationsForEvent}
                isSeeingThroughMessageHiddenForModeration={this.props.isSeeingThroughMessageHiddenForModeration}
                isPreviewReply={this.props.isPreviewReply}
                isRightPanelPinnedMessage={this.props.isRightPanelPinnedMessage}
                isFilePanel={this.props.isFilePanel}
                fileCaption={this.props.fileCaption}
                previewTitle={this.props.previewTitle}
                setMediaDeleted={this.props.setMediaDeleted}
            />
        ) : null;

       // Handle forwarded event
       const forwardedInfo = getForwardedEventInfo(this.props.mxEvent.getContent());
       if (forwardedInfo) {
           let maxWidthEvent;
           const isImageOrVideoEvent = (
               type === EventType.RoomMessage &&
               layout === Layout.Bubble && // Only customize width with Bubble layout
               (msgtype === MsgType.Image || msgtype === MsgType.Video)
           );
           if (isImageOrVideoEvent && content.info?.w && content.info?.h) {
               const MAX_SIZE = 325;
               const MAX_HEADER_FORWARD_EVENT = 320;
               // CTalk force setting size image Normal
               // const size = SettingsStore.getValue("Images.size") as ImageSize;
               const size = ImageSize.Normal;
               const { w: maxWidth } = suggestedImageSize(
                   size,
                   { w: content.info.w, h: content.info.h },
               );
               maxWidthEvent = maxWidth > MAX_SIZE ? maxWidth : MAX_HEADER_FORWARD_EVENT;
           }
           return (
               <div className="ctalk_EventTile_message_forward"
                   style={{
                       maxWidth:
                           !this.props.isPreviewReply
                           && isImageOrVideoEvent
                           && maxWidthEvent
                               ? maxWidthEvent
                               : 'unset',
                   }}
               >
                   {!this.props.isRightPanelPinnedMessage && this.forwardedEvent(forwardedInfo) }
                   {bodyType}
               </div>
           );
        }

        return bodyType;
    }
}
