import { MsgType } from "matrix-js-sdk/src/matrix";

export function dataURItoBlob(dataURI: string): Blob {
    // convert base64 to raw binary data held in a string
    const byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
}

export function getEmojiFileType(type: string | undefined): string {
    let emoji;
    switch (type) {
        case MsgType.Image:
            emoji = '🖼️';
            break;
        case MsgType.Video:
            emoji = '📹';
            break;
        case MsgType.Audio:
            emoji = '🎵';
            break;
        default:
            emoji = '📎';
            break;
    }
    return emoji;
}
